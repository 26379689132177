<template>

  <van-field v-model="choosedTime" label="选择服务时间" label-width="105px" required type="textarea" rows="1" autosize
             placeholder="点击选择服务时间"
             readonly @click="chooseTime"/>

  <van-popup
      v-model:show="showButton.showPopup"
      position="bottom"
  >
    <van-datetime-picker
        v-if="showButton.showStartTimePicker"
        v-model="queryCondition.startTime"
        type="datetime"
        title="选择开始时间"
        :min-date="startMinDate"
        :max-date="startMaxDate"
        @confirm="confirmStartTime"
        @cancel="cancleStartTime"
        :filter="filter"
    />
    <van-datetime-picker
        v-if="showButton.showEndTimePicker"
        v-model="queryCondition.endTime"
        type="datetime"
        title="选择结束时间"
        :min-date="endMinDate"
        :max-date="endMaxDate"
        @cancel="cancleEndTime"
        @confirm="confirmEndTime"
        :filter="filter"
    />
  </van-popup>
</template>

<script>
import {reactive, toRefs} from 'vue'

export default {
  name: 'chooseTime',
  components: {},
  emits:['startTime','endTime'],
  setup(props, context) {


    let data = reactive({
      showButton: {showStartTimePicker: false, showPopup: false, showEndTimePicker: false},

      choosedTime: '',
      startMinDate: new Date(),
      startMaxDate: new Date(),
      endMinDate: {},
      endMaxDate: {},
      queryCondition: {startTime: '', endTime: ''},

    });

    const methodsMap = {

      addDate: (date, type, numbers) => {
        if (type == 'day') {
          // let time = new Date(date.getFullYear(), date.getMonth(), date.getDay() + numbers)
          // return new Date(time.setHours(22))
          return new Date(date.setDate(date.getDate() + numbers))
        } else if (type == 'hour') {
          return new Date(date.setHours(date.getHours() + numbers))
        } else if (type == 'minute') {
          return new Date(date.setMinutes(date.getMinutes() + numbers))
        }
      },

      floorDate: (date) => {

        let m = date.getMinutes();
        let h = date.getHours();
        if (m >= 30) {
          m = 0
          h += 1
        }
        date.setHours(h)
        date.setMinutes(m)
        return new Date(date)

      },
      formatDate: (date, fmt) => {
        var o = {
          "M+": date.getMonth() + 1, //月份
          "d+": date.getDate(), //日
          "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
          "H+": date.getHours(), //小时
          "m+": date.getMinutes(), //分
          "s+": date.getSeconds(), //秒
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度
          "S": date.getMilliseconds() //毫秒
        };
        var week = {
          "0": "/u65e5",
          "1": "/u4e00",
          "2": "/u4e8c",
          "3": "/u4e09",
          "4": "/u56db",
          "5": "/u4e94",
          "6": "/u516d"
        };
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        if (/(E+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" : "/u5468") : "") + week[date.getDay() + ""]);
        }
        for (var k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
          }
        }
        return fmt;
      },
      filter: (type, options) => {
        if (type === 'minute') {
          return options.filter((option) => Number(option) % 30 === 0);
        }
        if (type === 'hour') {
          return options.filter((option) => option >= 7 && option <= 22);
        }
        return options;
      },
      chooseTime: () => {
        data.startMinDate = methodsMap.floorDate(new Date())
        data.startMaxDate = methodsMap.addDate(new Date(), 'day', 7)
        data.showButton = {showStartTimePicker: true, showPopup: true, showEndTimePicker: false}

      },
      confirmStartTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: true, showEndTimePicker: true}
        data.endMinDate = methodsMap.addDate(data.startMinDate, 'minute', 1)
        data.endMaxDate = methodsMap.addDate(new Date(), 'day', 7)
        context.emit('startTime', data.queryCondition.startTime)
      },
      cancleStartTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: false, showEndTimePicker: false}
      },
      confirmEndTime: () => {
        data.showButton = {showStartTimePicker: false, showPopup: false, showEndTimePicker: false}
        data.choosedTime = methodsMap.formatDate(data.queryCondition.startTime, 'yyyy-MM-dd HH:mm') + "至" +
            methodsMap.formatDate(data.queryCondition.endTime, 'yyyy/MM/dd HH:mm')
        context.emit('endTime', data.queryCondition.endTime)
      },
      cancleEndTime: () => {
        data.showButton = {showStartTimePicker: true, showPopup: true, showEndTimePicker: false}

      },
    }

    return {
      ...toRefs(data),
      ...methodsMap
    }
  }
}
</script>

<style lang="less" scoped>

.keyboardNav {
  --van-number-keyboard-title-font-size: 10px;
}

.vanButton {

  .vanButtonFix {
    position: fixed;
    height: 30px;
    bottom: 20px;
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

</style>