<template>
  <div class="publish-job app_content">
    <div class="warp_body">
      <div class="publishJobBody">
        <van-form @submit="onSubmit">
          <van-cell-group inset>

            <van-field v-model="form.title" rows="1"
                       autosize
                       required
                       maxlength="100"
                       type="textarea" label="项目名称"
                       placeholder="请输入项目名称" :rules="[{ validator: validatorJobTitleMessage }]"/>
            <!-- 输入任意文本 -->
            <van-field v-model="form.companyName" required label="单位名称" placeholder="请输入用工单位名称"
                       :rules="[{ validator: validatorCompanyMessage }]"/>
            <van-field
                v-model="form.areaText"
                required
                is-link
                readonly
                name="picker"
                label="单位地域"
                placeholder="点击选择区域"
                @click="getAreaList"
                :rules="[{ validator: validatorAreaMessage }]"
            />
            <van-popup v-model:show="showAreaPicker" position="bottom">
              <van-picker
                  :columns="areaList"
                  @confirm="onConfirmArea"
                  @cancel="showAreaPicker = false"
              />
            </van-popup>
            <van-field v-model="form.companyAddress" rows="1"
                       autosize
                       required
                       maxlength="100"
                       type="textarea" label="单位地址"
                       placeholder="请输入单位地址" :rules="[{ validator: validatorCompanyAddressMessage }]"/>

            <van-field
                v-model="form.content"
                rows="5"
                autosize
                required
                border="true"
                label="项目描述"
                type="textarea"
                placeholder="请输入您的项目描述方便招人"
                show-word-limit
            />

            <choose-time @startTime="getStartTime" @endTime="getEndTime"></choose-time>

          </van-cell-group>

          <div style="margin: 16px;text-align: center">
            <van-button round type="primary" style="width: 120px" native-type="submit">
              确认发布
            </van-button>
          </div>


        </van-form>


      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import req from "../request/request";
import API_ORDER from "../api/Order";
import API from "../api/User";
import router from "../router";
import ChooseTime from "@/components/common/chooseTime";

export default {
  name: 'PublishJob',
  components: {ChooseTime},
  setup() {

    onMounted(() => {
    });

    const data = reactive({
          showAreaPicker: false,
          areaList: [],
          form: {
            areaId: '',
            companyArea: '',
            areaText: '',
            companyName: '',
            companyAddress: '',
            content: '',
            title: '',
            startTime: '',
            endTime: '',

          }
        },
    )

    const methodsMap = {
      getStartTime: (val) =>{
        data.form.startTime=val;
        console.log('startTime',val)
      },
      getEndTime: (val) =>{
        data.form.endTime=val;
        console.log('endTime',val)
      },
      validatorCompanyMessage: (val) => {
        if (!val) {
          return '请输入单位名称'
        }
      },
      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      validatorJobTitleMessage: (val) => {
        if (!val) {
          return '请输入项目名称'
        }
      },
      validatorCompanyAddressMessage: (val) => {
        if (!val) {
          return '请输入用工单位详细地址'
        }
      },
      onConfirmArea: (item) => {
        data.showAreaPicker = false
        data.form.areaText = item.text
        data.form.companyArea = item.value
        data.form.areaId = item.id
      },
      onSubmit: (form) => {
        console.log('submit', form)
        let params = data.form
        req.request(API_ORDER.publishJob , params,
            {method: 'POST'}, {message: '注册中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/')
                Toast.success("发布成功！")
              }
            })

      },

      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
            })
      },
      changeVerificationCode: (val) => {
        data.form.verificationCode = val
      },
      changePhone: (val) => {
        console.log("data.form.companyPhone:", val)
        data.form.companyPhone = val
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.publishJobBody {
}
</style>
